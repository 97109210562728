import { useCallback, useEffect, useState } from 'react';
import { usePrevious } from 'react-use';

import { usePopup } from '#root/hooks/use-popup';
import { useTabActive } from '#root/hooks/use-tab-active';
import { getService } from '#root/store';

export const useLoginPopup = (onError?: () => void) => {
  const [loginInitiated, setLoginInitiated] = useState(false);
  const onCheckLogin = useCallback(async () => {
    await getService('app').loadUser();
    setLoginInitiated(false);
  }, []);
  const onLoginMessage = useCallback(
    (event: MessageEvent<any>) => {
      const { data } = event;
      if (data.source === 'login-connect-success') {
        if (data.success) {
          onCheckLogin();
        } else {
          // Failure with login.
          onError?.();
        }
      }
    },
    [onError]
  );
  const { open, isOpen } = usePopup('login', onLoginMessage);

  const openLoginPopup = useCallback((url: string) => {
    setLoginInitiated(true);
    return open(url);
  }, []);
  useEffect(() => {
    // This is a workaround to make facebook login work. For some reason facebook redirects or rearrance the popup when
    // you also need to login on facebook, resulting that the parent<->popup relation does not work.
    const handler = (event: StorageEvent) => {
      if (event.key === 'loginSuccess' && event.newValue === 'true') {
        onCheckLogin();
        try {
          window.localStorage?.removeItem('loginSuccess');
        } catch (e) {
          //
        }
      }
    };
    window.addEventListener('storage', handler, false);
    return () => {
      window.removeEventListener('storage', handler);
    };
  }, []);

  // Workarround if the localstorage workaround does not work. This does not work on desktop though.
  const tabIsActive = useTabActive();
  const prevTabIsActive = usePrevious(tabIsActive);

  useEffect(() => {
    if (prevTabIsActive === false && tabIsActive) {
      onCheckLogin();
    }
  }, [tabIsActive]);

  // Also a workaround if the popup was closed but for some reason all other measures did not work.
  useEffect(() => {
    if (!isOpen) {
      onCheckLogin();
    }
  }, [isOpen]);

  return {
    openLoginPopup,
    loginInitiated,
    onCheckLogin,
  };
};
