import {
  Button,
  ButtonBase,
  Checkbox,
  FormControlLabel,
  TextField,
  Typography,
} from '@mui/material';
import { captureException } from '@sentry/react';
import clsx from 'clsx';
import React, { FC, useCallback, useState } from 'react';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';

import FacebookLogo from './../../Images/Facebook.png';
import { ReactComponent as GoogleSvg } from './../../Images/Google.svg';
import s from './LoginForm.module.scss';
import { useLoginPopup } from './use-login-popup';

import { Container } from '#root/Components/Container';
import { Flex, Item } from '#root/Components/Flex';
import { Link } from '#root/Components/Link';
import { Loading } from '#root/Components/Loading';
import { useRedirectIfLoggedIn } from '#root/hooks/use-redirect-if-logged-in';
import { useTranslation } from '#root/hooks/use-translation';
import { getService } from '#root/store';
import { Keys } from '#root/translations-keys';
import { isMobile } from '#root/utils/is-mobile';

export interface ILoginForm {
  email: string;
  password: string;
  remember_me?: boolean;
}

export const LoginForm: FC = () => {
  useRedirectIfLoggedIn();
  const t = useTranslation();
  const { register, handleSubmit, control } = useForm<ILoginForm>();

  const [submitting, setSubmitting] = useState(false);
  const [error, setError] = useState<string>();
  const [socialError, setSocialError] = useState<string>();
  const onLoginError = useCallback(() => {
    setSocialError(t(Keys.login.not_found));
  }, []);
  const { openLoginPopup, loginInitiated, onCheckLogin } = useLoginPopup(onLoginError);

  const onOpenSocialPopup = (url: string) => {
    setSocialError(undefined);
    openLoginPopup(`${url}&state=${btoa(JSON.stringify({ type: 'login' }))}`);
  };
  const onSubmit: SubmitHandler<ILoginForm> = data => {
    if (submitting) return;
    setSubmitting(true);
    setError(undefined);

    getService('app')
      .login(data)
      .then(() => {
        setSubmitting(false);
      })
      .catch(async error => {
        setSubmitting(false);
        if (error.response) {
          try {
            const body = await error.response.json();

            if (body.error) {
              setError(body.error);
            }
          } catch (e) {
            console.error(error); // Try to track what is going on here.
            captureException(e);
          }
        } else {
          console.error(error);
          captureException(error);
        }
      });
  };

  return (
    <Container small marginTop>
      <form noValidate onSubmit={handleSubmit(onSubmit)} className={s.loginForm}>
        <Flex gap column>
          <Flex column gap className={s.socialLogins}>
            <ButtonBase
              onClick={() =>
                onOpenSocialPopup('/connect/facebook?_destination=/login/connect-login-success')
              }
              className={clsx(s.facebook, s.socialButton)}
              color="secondary"
            >
              <div>
                <img src={FacebookLogo} /> {t(Keys.login.facebook)}
              </div>
            </ButtonBase>
            <ButtonBase
              onClick={() =>
                onOpenSocialPopup('/connect/google?_destination=/login/connect-login-success')
              }
              className={clsx(s.google, s.socialButton)}
              color="secondary"
            >
              <div>
                <GoogleSvg /> {t(Keys.login.google)}
              </div>
            </ButtonBase>
          </Flex>
          {socialError && (
            <Typography align="center" color="error">
              {socialError}
            </Typography>
          )}
          <div className={s.divider}>
            <div className={s.line}></div>
            <div className={s.or}>{t(Keys.login.or)}</div>
          </div>
          <p className={s.loginWithEmail}>{t(Keys.login.with_email)}</p>
          <TextField
            variant="outlined"
            required
            fullWidth
            label={t(Keys.generic.email)}
            autoComplete="email"
            autoFocus={!isMobile()}
            inputProps={register('email')}
          />
          <TextField
            variant="outlined"
            required
            fullWidth
            label={t(Keys.generic.password)}
            type="password"
            inputProps={register('password')}
          />
          {error && <Typography color="error">{error}</Typography>}
          <Button
            disableElevation
            type="submit"
            fullWidth
            variant="contained"
            color="primary"
            disabled={submitting}
          >
            {t(Keys.login.login)}
          </Button>
          <FormControlLabel
            control={
              <Controller
                control={control}
                name="remember_me"
                render={({ field: { value, ...rest } }) => (
                  <Checkbox color="primary" checked={!!value} {...rest} />
                )}
              />
            }
            label={t(Keys.login.remember_me)}
          />
          <Flex gap justify="space-between">
            <Item>
              <Link to="/password">{t(Keys.login.forgot_password)}?</Link>
            </Item>
            <Item>
              <Link to="/register">{t(Keys.login.signup)}</Link>
            </Item>
          </Flex>
        </Flex>
        {loginInitiated ? (
          <div className={s.loginProgress}>
            <Loading />
            {t(Keys.login.some.attempt_description)}
            <Button color="secondary" variant="outlined" onClick={onCheckLogin}>
              {t(Keys.login.some.complete_login)}
            </Button>
          </div>
        ) : null}
      </form>
    </Container>
  );
};
