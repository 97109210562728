import { Button, DialogContent, TextField } from '@mui/material';
import React, { FC, useState } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';

import { Flex } from '#root/Components/Flex';
import { Loading } from '#root/Components/Loading';
import { useNavigate } from '#root/hooks/use-navigate';
import { useTranslation } from '#root/hooks/use-translation';
import { getService } from '#root/store';
import { IEditableParty } from '#root/store/types';
import { Keys } from '#root/translations-keys';
import { api } from '#root/utils/api';
import { css } from '#root/utils/css';

interface Form {
  field: string;
}

export const RemoveParty: FC<{ onClose: () => void; party: IEditableParty }> = ({
  onClose,
  party,
}) => {
  const navigate = useNavigate();
  const t = useTranslation();
  const [submitting, setSubmitting] = useState(false);
  const [message, setMessage] = useState<string | undefined>();
  const {
    register,
    handleSubmit,
    formState: { isValid },
  } = useForm<Form>();

  const onSubmit: SubmitHandler<Form> = () => {
    if (submitting) return;

    setMessage(undefined);
    setSubmitting(true);

    api
      .delete(`/api/party/${party.id}`)
      .then(() => {
        getService('party').unload();
        navigate('/parties');
      })
      .catch(() => {
        setMessage(t(Keys.generic.error_try_again));
      })
      .finally(() => {
        setSubmitting(false);
      });
  };
  const disabled = submitting || !isValid;

  return (
    <DialogContent>
      <form onSubmit={handleSubmit(onSubmit)}>
        <h2>
          {t(Keys.admin.settings.remove.title)} "{party.title}"
        </h2>
        <p>{t(Keys.admin.settings.remove.description)}</p>
        <TextField
          type="text"
          variant="outlined"
          margin="normal"
          color="error"
          error
          fullWidth
          label={t(Keys.admin.settings.remove.textfield_label)}
          autoComplete="off"
          inputProps={register('field', {
            required: true,
            validate: {
              isCorrect: val => val.toLowerCase() === t(Keys.admin.settings.remove.textfield_value),
            },
          })}
        />
        {message ? <p>{message}</p> : null}
        <Flex justify="space-between" className={css.spaceTopL}>
          <Button variant="outlined" color="secondary" disableElevation onClick={onClose}>
            {t(Keys.generic.cancel)}
          </Button>
          <Button
            type="submit"
            variant="contained"
            color="error"
            disabled={disabled}
            disableElevation
          >
            {submitting ? <Loading color="error" /> : t(Keys.admin.settings.remove.confirm)}
          </Button>
        </Flex>
      </form>
    </DialogContent>
  );
};
