import React, { useCallback } from 'react';

export const useOptimisticData = <T>(
  setData: React.Dispatch<React.SetStateAction<T[]>>,
  idField: keyof T
) => {
  const add = useCallback((item: T) => setData(prev => [...(prev ?? []), item]), []);
  const remove = useCallback(
    (item: T) => setData(prevs => (prevs ?? []).filter(prev => prev[idField] !== item[idField])),
    [idField]
  );
  const alter = useCallback(
    (item: T) =>
      setData(prevs => prevs.map(prev => (prev[idField] === item[idField] ? item : prev))),
    [idField]
  );

  return {
    add,
    remove,
    alter,
  };
};
