import { Info, NotInterested } from '@mui/icons-material';
import { Tooltip } from '@mui/material';
import React, { FC } from 'react';

import { Accordion } from '#root/Components/Accordion';
import { Flex } from '#root/Components/Flex';
import { InfoBox } from '#root/Components/InfoBox';
import { Cell, Row, Table } from '#root/Components/Table';
import { useTranslation } from '#root/hooks/use-translation';
import { IContact } from '#root/store/types';
import { Keys } from '#root/translations-keys';
import { CloseIcon, DoneIcon, MailIcon, PhoneIcon } from '#root/utils/icons';

export interface IProps {
  response: {
    tokensUsed: number;
    emailsSent: number;
    textSent: number;
    errors: number;
    contacts: IContact[];
  };
  templateId: string;
}

const RenderStatus: FC<{ status?: string }> = ({ status }) => {
  const t = useTranslation();

  if (!status) {
    return null;
  }

  if (['failed', 'insufficient_funds'].includes(status)) {
    return (
      <Tooltip title={t(Keys.admin.message.send.error)}>
        <CloseIcon color="error" />
      </Tooltip>
    );
  }

  if (status === 'send') {
    return <DoneIcon color="primary" />;
  }

  if (status === 'unsubscribed') {
    return (
      <Tooltip title={t(Keys.admin.message.send.unsubscribed)}>
        <NotInterested color="error" />
      </Tooltip>
    );
  }

  if (status === 'received') {
    return <DoneIcon color="primary" />;
  }

  return null;
};

export const SendMessageResponse: FC<IProps> = ({ response, templateId }) => {
  const t = useTranslation();

  const someIsSend = response.emailsSent > 0 || response.textSent > 0;
  const state = someIsSend && response.errors > 0 ? 'warning' : someIsSend ? 'success' : 'error';

  return (
    <Flex column gap align="center">
      {state === 'success' ? (
        <DoneIcon color="primary" fontSize="large" />
      ) : state !== 'warning' ? (
        <CloseIcon color="error" fontSize="large" />
      ) : null}
      <InfoBox type={state}>{t(Keys.admin.message.send[state])}</InfoBox>

      {state !== 'success' ? (
        <div style={{ width: '100%' }}>
          <Accordion
            compact
            preTitle={<Info color="action" fontSize="small" />}
            title={t(Keys.admin.message.send.details)}
          >
            <div style={{ maxHeight: 300, overflowY: 'auto' }}>
              <Table template="auto auto auto 50px 50px" striped>
                <Row>
                  <Cell></Cell>
                  <Cell></Cell>
                  <Cell></Cell>
                  <Cell>
                    <Tooltip title={t(Keys.admin.selection.receives_email)}>
                      <MailIcon fontSize="small" />
                    </Tooltip>
                  </Cell>
                  <Cell>
                    <Tooltip title={t(Keys.admin.selection.receives_text)}>
                      <PhoneIcon fontSize="small" />
                    </Tooltip>
                  </Cell>
                </Row>
                {response.contacts.map(contact => {
                  const message = contact.messages.find(message => message.template === templateId);
                  return (
                    <Row key={contact.id}>
                      <Cell>{contact.name}</Cell>
                      <Cell>{contact.email}</Cell>
                      <Cell>{contact.phone}</Cell>
                      <Cell>
                        <RenderStatus status={message?.statusMail} />
                      </Cell>
                      <Cell>
                        <RenderStatus status={message?.statusText} />
                      </Cell>
                    </Row>
                  );
                })}
              </Table>
            </div>
          </Accordion>
        </div>
      ) : null}
    </Flex>
  );
};
