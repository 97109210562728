import React, { FC, useEffect, useState } from 'react';

import { parameters } from '#root/store/parameters';

export const ConnectSuccess: FC = () => {
  const [canClose, setCanClose] = useState(false);

  useEffect(() => {
    if (window.opener) {
      window.opener.postMessage({
        source: 'login-connect-success',
        success: parameters.connectSuccess ?? false,
      });
    } else {
      try {
        window.localStorage?.setItem('loginSuccess', 'true');
      } catch (e) {
        //
      }
    }
    setTimeout(() => {
      window.close();
      setCanClose(true);
    }, 1000);
  });
  // some text to show the user
  return (
    <div>
      <p>Please wait...</p>
      {canClose && (
        <button onClick={() => window.close()}>Close this window and return to invii.me</button>
      )}
    </div>
  );
};
